<template>
  <div class="w-100 my-5">
    <div class="box-white vertical-center">
      <span class="font-bold font-15 alpha-07">{{ $t('not_reminded_guests') }}:</span>
      <span class="mx-5 font-bold font-20 color-blue">{{ getDecimalFormat(userList.length) }}</span>
      <v-btn class="mx-5 button-normal" small @click="remindGuestUserTask(true)">
        <span>{{ $t('remind_all') }}</span>
      </v-btn>
    </div>
    <div class="row my-5" v-if="(userList.length > 0)">
      <div :class="`col-sm${colSmSize}`" v-for="(item, index) in userList" :key="(item, index)">
        <div class="my-2 box-white d-flex flex-column">
          <div class="vertical-center">
            <img class="circle-image-80" :src="item.thumbnailUrl || item.photoUrl || assets.profile" />
            <div class="ml-5 d-flex flex-column">
              <div class="mt-2 vertical-center" v-if="item.email">
                <i class="socicon-mail font-12 color-blue"></i>
                <span class="ml-2 font-12">{{ getUserEmail(item.email) }}</span>
              </div>
              <div class="mt-2 vertical-center" v-if="item.phone">
                <i class="fa fa-phone font-12 color-blue"></i>
                <span class="ml-2 font-12">{{ item.phone }}</span>
              </div>
              <div class="mt-2 vertical-center" v-if="item.deviceType">
                <i class="fa fa-mobile-alt font-20 color-blue"></i>
                <span :class="`ml-2 w-100 font-12 ellipsis ${item.deviceTypeColor}`" :title="item.deviceType">{{ item.deviceType.substring(0, 40) }}</span>
              </div>
            </div>
          </div>
          <div class="mt-5 vertical-center">
            <div class="w-80px mr-5" v-if="!item.deletedAt && !item.blockedAt">&nbsp;</div>
            <div class="vertical-center mr-auto">
              <i class="fa fa-calendar-alt font-12 color-blue"></i>
              <span class="ml-2 font-12">{{ getDateStringFromTimestamp(item.createdAt) }}</span>
            </div>
            <div class="vertical-center" v-if="item.blockedAt">
              <i class="fa fa-calendar-alt font-12 color-red"></i>
              <span class="ml-2 font-12 color-red">{{ getDateStringFromTimestamp(item.blockedAt) }}</span>
              &nbsp;
              <i class="font-12 color-red">[Blocked]</i>
            </div>
            <div class="vertical-center" v-if="item.deletedAt">
              <i class="fa fa-calendar-alt font-12 color-red"></i>
              <span class="ml-2 font-12 color-red">{{ getDateStringFromTimestamp(item.deletedAt) }}</span>
              &nbsp;
              <i class="font-12 color-red">[{{ $t('deleted') }}]</i>
            </div>
          </div>
          <div class="mt-5 d-flex justify-content-end">
            <v-btn class="ml-5 button-red" small @click="remindGuestUserTask(false, item.userId, item.email)">
              <span>{{ $t('remind') }}</span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="py-40 center" v-if="(userList.length === 0)">
      <span class="font-20 alpha-07">There is no guests that was not reminded yet.</span>
    </div>
  </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions, firestore } from '../../../main';
import { collection, query, where, orderBy, limit, getDocs, Timestamp } from 'firebase/firestore';
import { getDecimalFormat, getDateStringFromTimestamp, getUserEmail, showLoading } from '../../../functions';

import profile from '@/assets/image/profile.jpg';

export default {
  name: 'RemindGuests',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    colSmSize() {
      return this.$store.state.colSmSize;
    }
  },
  data() {
    return {
      assets: {
        profile
      },
      userList: []
    }
  },
  mounted() {
    this.getUserListTask();
  },
  methods: {
    getDecimalFormat,
    getDateStringFromTimestamp,
    getUserEmail,
    getUserListTask() {
      const loader = showLoading(this, 1);
      const q = query(collection(firestore, 'user'), where('userType', '==', 2), where('remindedAt', '==', null), orderBy('createdAt', 'asc'), limit(this.PAGE_LIMIT_200));
      getDocs(q).then(querySnapshot => {
        loader.hide();
        const list = [];
        const userList = this.$store.state.userList;
        querySnapshot.forEach(doc => {
          const data = doc.data();
          const deviceType = data.deviceType || '';
          if (window.location.hostname === 'localhost') {
            data['deviceType'] = deviceType;
            if (deviceType.startsWith('Android : ')) {
              data['deviceTypeColor'] = 'color-red';
            } else if (deviceType.startsWith('iOS : ')) {
              data['deviceTypeColor'] = 'color-blue';
            } else if (deviceType.startsWith('Web : ')) {
              data['deviceTypeColor'] = 'color-purple';
            }
          } else {
            const myArray = deviceType.split(' : ');
            if (myArray && myArray.length > 0) {
              data['deviceType'] = myArray[0];
            } else {
              data['deviceType'] = '';
            }
            data['deviceTypeColor'] = 'color-primary';
          }
          list.push(data);
          if (!userList.find(element => element.userId === data.userId)) {
            userList.push(data);
          }
        });
        this.userList = list;
        this.$store.commit('set', ['userList', userList]);
      }).catch(error => {
        loader.hide();
        this.$toast.error('Unexpected error occurs - ' + error.message);
      });
    },
    remindGuestUserTask(isAll, userId = null, email = null) {
      const params = {
        functionName: 'remindGuestUser'
      };
      if (isAll === true) {
        if (this.userList.length === 0) {
          this.$toast.info('No guest to remind.');
          return;
        }
        if (!confirm(`Are you sure you want to remind ${this.userList.length} guests?`)) {
          return;
        }
        const guestList = [];
        this.userList.forEach(userInfo => {
          guestList.push({
            userId: userInfo.userId,
            email: userInfo.email
          });
        });
        params['guestList'] = guestList;
      } else {
        if (!userId) {
          this.$toast.error('User ID is required.');
          return;
        }
        if (!email) {
          this.$toast.error('Email is required.');
          return;
        }
        params['userId'] = userId;
        params['email'] = email;
      }
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          if (isAll === true) {
            this.getUserListTask();
          } else {
            for (var i = this.userList.length; i--;) {
              if (this.userList[i].userId === userId) {
                this.userList.splice(i, 1);
              }
            }
          }
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    setRemindedAtTask() {
      const guestIDs = [];
      this.userList.forEach(userInfo => {
        if (!userInfo.hasOwnProperty('remindedAt')) {
          guestIDs.push(userInfo.userId);
        }
      });
      if (guestIDs.length === 0) {
        this.$toast.info('No guest to set!');
        return;
      }
      const params = {
        functionName: 'setRemindedAt',
        guestIDs: guestIDs
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>